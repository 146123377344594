import { collection, addDoc, updateDoc, deleteDoc, getDocs, query, where, doc, getDoc, runTransaction } from 'firebase/firestore';
import { db } from './config';
import { User } from 'firebase/auth';
import type { BankAccount } from '../types/BankAccount';
import { clearCache } from '../utils/indexedDB';

export const addBankAccount = async (account: Omit<BankAccount, 'id'>, user: User): Promise<BankAccount> => {
  const docRef = await addDoc(collection(db, 'bankAccounts'), account);
  await clearCache();
  return { ...account, id: docRef.id };
};

export const updateBankAccount = async (account: BankAccount, user: User): Promise<void> => {
  const docRef = doc(db, 'bankAccounts', account.id);
  await updateDoc(docRef, { ...account, userId: user.uid });
  await clearCache();
};

export const deleteBankAccount = async (id: string, user: User): Promise<void> => {
  const docRef = doc(db, 'bankAccounts', id);
  await deleteDoc(docRef);
  await clearCache();
};

export const getBankAccounts = async (user: User): Promise<BankAccount[]> => {
  const q = query(collection(db, 'bankAccounts'), where('userId', '==', user.uid));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id } as BankAccount));
};

export const updateBankAccountBalance = async (bankAccountId: string, newBalance: number) => {
  const bankAccountRef = doc(db, 'bankAccounts', bankAccountId);
  
  await updateDoc(bankAccountRef, {
    balance: newBalance,
    lastUpdated: new Date().toISOString()
  });

  return newBalance;
};

export async function updateBalance(accountId: string, amount: number) {
  const accountRef = doc(db, 'bankAccounts', accountId);

  try {
    const newBalance = await runTransaction(db, async (transaction) => {
      const accountSnap = await transaction.get(accountRef);
      if (!accountSnap.exists()) {
        throw new Error('Account not found');
      }

      const currentBalance = accountSnap.data().balance;
      const newBalance = currentBalance + amount;
      transaction.update(accountRef, { balance: newBalance });
      return newBalance;
    });

    return newBalance;
  } catch (error) {
    console.error('Error updating balance:', error);
    throw error;
  }
}