import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, FormControl, InputLabel, IconButton, Grid, Button, SelectChangeEvent, Tooltip, Dialog, DialogContent, DialogTitle, Pagination, DialogActions } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormattedMessage, useIntl } from 'react-intl';
import { Expense } from '../types/Expense';
import { FaEdit, FaTrash, FaSort, FaSortUp, FaSortDown, FaExchangeAlt, FaTag, FaSearch, FaCalendarAlt, FaEuroSign, FaUser, FaSave, FaTimes, FaUpload, FaFileExport, FaSync, FaEraser, FaFileInvoice } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import CustomChip from './CustomChip';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';
import { setCache, getCache } from '../utils/indexedDB';
import { categoryEmojis } from '../utils/categoryEmojis';
import { expensesCache } from '../firebase/firestoreOperations';
import { removeAccents } from '../utils/stringUtils'; // Importe esta função
import { Share, MoneyOff, Remove, People, Person, CreditCard } from '@mui/icons-material';
import { format } from 'date-fns';

interface FilteredExpensesProps {
  expenses: Expense[];
  filter: {
    year: number;
    month: number | 'all';
    payer: string;
  };
  payers: string[];
  categories: string[];
  onEditExpense: (expense: Expense, file?: File) => Promise<void>;
  onDeleteExpense: (id: string) => void;
  payerColors: { [key: string]: string };
  userId: string;
  startDate: Date;
  endDate: Date;
}

type SortableExpenseKey = keyof Omit<Expense, 'id' | 'fileUrl'> | 'type' | 'sharedWithOrDebtFrom';

interface Column {
  id: SortableExpenseKey | 'actions' | 'receipt';
  label: React.ReactNode;
  render: (expense: Expense, isEditing: boolean, editingExpense: Expense | null, handleEditChange: any, handleSharedChange: any) => React.ReactNode;
}

type SortConfig = {
  key: SortableExpenseKey;
  direction: 'asc' | 'desc';
} | null;

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutos em milissegundos

const FilteredExpenses: React.FC<FilteredExpensesProps> = ({ 
  expenses, 
  filter, 
  payers, 
  categories, 
  onEditExpense, 
  onDeleteExpense, 
  payerColors, 
  userId, 
  startDate, 
  endDate 
}) => {
  const intl = useIntl();
  const [selectedPayer, setSelectedPayer] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [descriptionFilter, setDescriptionFilter] = useState<string>('');
  const [editingExpense, setEditingExpense] = useState<Expense | null>(null);
  const [sortConfig, setSortConfig] = useState<SortConfig>(null);

  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState('');

  const [editingFile, setEditingFile] = useState<File | null>(null);

  const [fetchedExpenses, setFetchedExpenses] = useState<Expense[]>([]);
  const [lastFetch, setLastFetch] = useState<number | null>(null);

  const [startDateState, setStartDate] = useState<Date | null>(null);
  const [endDateState, setEndDate] = useState<Date | null>(null);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ITEMS_PER_PAGE = 10; // You can adjust this value as needed

  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('');

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  useEffect(() => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, [startDate, endDate]);

  const fetchExpenses = useCallback(async () => {
    try {
      const cacheKey = `expenses_${userId}_${startDateState?.toISOString() || ''}_${endDateState?.toISOString() || ''}`;
      let cachedExpenses = await getCache(cacheKey);

      if (!cachedExpenses) {
        const expensesRef = collection(db, 'expenses');
        let q = query(expensesRef, where('userId', '==', userId));

        if (startDateState) {
          q = query(q, where('date', '>=', startDateState));
        }
        if (endDateState) {
          q = query(q, where('date', '<=', endDateState));
        }

        const querySnapshot = await getDocs(q);
        cachedExpenses = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date.toDate()
        })) as Expense[];
        
        await setCache(cacheKey, cachedExpenses, CACHE_DURATION);
      }

      // Use o cache global atualizado
      const globalCachedExpenses = expensesCache.get(userId) || [];
      const updatedExpenses = cachedExpenses.map((expense: Expense) => {
        const updatedExpense = globalCachedExpenses.find((e: Expense) => e.id === expense.id);
        return updatedExpense || expense;
      });
      
      console.log('Fetched expenses:', updatedExpenses);
      setFetchedExpenses(updatedExpenses);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  }, [userId, startDateState, endDateState]);

  useEffect(() => {
    fetchExpenses();
  }, [fetchExpenses, categories]); // Adicione categories como dependência

  const filteredExpenses = useMemo(() => {
    console.log('Filtering expenses:', fetchedExpenses);
    return fetchedExpenses.filter(expense => {
      const expenseDate = new Date(expense.date);
      console.log('Expense date:', expenseDate, 'Start date:', startDateState, 'End date:', endDateState);
      
      const dateMatch = 
        (!startDateState || expenseDate >= startDateState) && 
        (!endDateState || expenseDate <= endDateState);
      const descriptionMatch = removeAccents(expense.description.toLowerCase()).includes(removeAccents(descriptionFilter.toLowerCase()));
      const payerMatch = selectedPayer === '' || expense.payer === selectedPayer;
      const categoryMatch = selectedCategory === '' || expense.category === selectedCategory;
      const monthMatch = selectedMonth === '' || expenseDate.getMonth() + 1 === parseInt(selectedMonth);
      const yearMatch = selectedYear === '' || expenseDate.getFullYear() === parseInt(selectedYear);
      
      console.log('Filters:', { dateMatch, descriptionMatch, payerMatch, categoryMatch, monthMatch, yearMatch });
      
      return dateMatch && descriptionMatch && payerMatch && categoryMatch && monthMatch && yearMatch;
    });
  }, [fetchedExpenses, startDateState, endDateState, descriptionFilter, selectedPayer, selectedCategory, selectedMonth, selectedYear]);

  const sortedExpenses = useMemo(() => {
    let sortableExpenses = [...filteredExpenses];
    if (sortConfig !== null) {
      sortableExpenses.sort((a, b) => {
        let aValue: any;
        let bValue: any;

        if (sortConfig.key === 'type') {
          aValue = a.isDebt ? 'debt' : a.shared ? 'shared' : '-';
          bValue = b.isDebt ? 'debt' : b.shared ? 'shared' : '-';
        } else if (sortConfig.key === 'sharedWithOrDebtFrom') {
          aValue = a.shared ? a.sharedWith : a.isDebt ? a.debtFrom : '-';
          bValue = b.shared ? b.sharedWith : b.isDebt ? b.debtFrom : '-';
        } else {
          aValue = a[sortConfig.key as keyof Expense];
          bValue = b[sortConfig.key as keyof Expense];
        }

        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
        }
        if (typeof bValue === 'string') {
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableExpenses;
  }, [filteredExpenses, sortConfig]);

  // Mova este useEffect para depois das declarações de filteredExpenses e sortedExpenses
  useEffect(() => {
    console.log('fetchedExpenses:', fetchedExpenses);
    console.log('filteredExpenses:', filteredExpenses);
    console.log('sortedExpenses:', sortedExpenses);
  }, [fetchedExpenses, filteredExpenses, sortedExpenses]);

  const totalExpenses = useMemo(() => {
    return filteredExpenses.reduce((sum, expense) => sum + expense.amount, 0);
  }, [filteredExpenses]);

  const calculatePayerTotals = useMemo(() => {
    if (!selectedPayer) return null;

    const totalAfterDeductions = filteredExpenses.reduce((sum, expense) => {
      if (expense.payer === selectedPayer) {
        if (expense.shared) {
          // Assuming shared expenses are split equally
          return sum + (expense.amount / 2);
        } else if (expense.isDebt) {
          // Don't count debts in the total
          return sum;
        } else {
          return sum + expense.amount;
        }
      }
      return sum;
    }, 0);

    return { totalAfterDeductions };
  }, [filteredExpenses, selectedPayer]);

  const handleEdit = (expense: Expense) => {
    setEditingExpense({ ...expense });
    setEditingFile(null);
    setEditDialogOpen(true);
  };

  const handleSaveEdit = async () => {
    if (editingExpense) {
      try {
        await onEditExpense(editingExpense, editingFile || undefined);
        setEditingExpense(null);
        setEditingFile(null);
        setEditDialogOpen(false);
      } catch (error) {
        console.error("Error saving edited expense:", error);
        // Você pode adicionar uma notificação de erro aqui
      }
    }
  };

  const handleCancelEdit = () => {
    setEditingExpense(null);
    setEditingFile(null);
    setEditDialogOpen(false);
  };

  const handleDelete = async (id: string) => {
    console.log(`Attempting to delete expense with id: ${id}`);
    if (window.confirm(intl.formatMessage({ id: 'delete' }))) {
      console.log('Deletion confirmed');
      try {
        await onDeleteExpense(id);
        console.log(`Expense with id ${id} deleted successfully`);
        setFetchedExpenses(prevExpenses => {
          const newExpenses = prevExpenses.filter(expense => expense.id !== id);
          console.log('Updated expenses:', newExpenses);
          return newExpenses;
        });
      } catch (error) {
        console.error("Error deleting expense:", error);
      }
    } else {
      console.log('Deletion cancelled');
    }
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    if (editingExpense) {
      const { name, value } = e.target;
      setEditingExpense(prev => {
        if (!prev) return null;
        return {
          ...prev,
          [name]: name === 'amount' ? Number(value) : value,
        };
      });
    }
  };

  const handleSharedChange = (e: SelectChangeEvent<string>) => {
    if (editingExpense) {
      setEditingExpense(prev => {
        if (!prev) return null;
        return {
          ...prev,
          shared: e.target.value === 'yes',
        };
      });
    }
  };

  const requestSort = (key: SortableExpenseKey) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const [columns, setColumns] = useState<Column[]>([]);

  const handleOpenReceipt = (fileUrl: string) => {
    setSelectedReceipt(fileUrl);
    setOpenReceiptDialog(true);
  };

  const handleCloseReceipt = () => {
    setOpenReceiptDialog(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setEditingFile(event.target.files[0]);
    }
  };

  const exportToCSV = () => {
    const headers = ['Categoria', 'Descrio', 'Data', 'Montante', 'Pagador', 'Tipo', 'Partilhada Com / Dívida De'];
    const csvContent = [
      '\uFEFF' + headers.join(';'), // BOM for UTF-8
      ...sortedExpenses.map(expense => [
        `"${expense.category.replace(/"/g, '""')}"`,
        `"${expense.description.replace(/"/g, '""')}"`,
        new Date(expense.date).toLocaleDateString(),
        `"€${expense.amount.toFixed(2)}"`,
        `"${expense.payer.replace(/"/g, '""')}"`,
        `"${expense.isDebt ? 'Dívida' : expense.shared ? 'Partilhada' : '-'}"`,
        `"${(expense.shared && expense.sharedWith ? expense.sharedWith : expense.isDebt && expense.debtFrom ? expense.debtFrom : '-').replace(/"/g, '""')}"`
      ].join(';'))
    ].join('\r\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'despesas_filtradas.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Ordena as categorias alfabeticamente
  const sortedCategories = useMemo(() => {
    return [...categories].sort((a, b) => a.localeCompare(b));
  }, [categories]);

  useEffect(() => {
    setColumns([
      {
        id: 'category',
        label: (
          <Box display="flex" alignItems="center">
            <FaTag style={{ marginRight: '8px' }} />
            <FormattedMessage id="category" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <Select
              value={editingExpense?.category || ''}
              onChange={handleEditChange}
              name="category"
              fullWidth
            >
              {categories.map(cat => (
                <MenuItem key={cat} value={cat}>
                  {categoryEmojis[cat] || ''} {cat}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <span>
              {categoryEmojis[expense.category] || ''} {expense.category}
            </span>
          )
        ),
      },
      {
        id: 'description',
        label: (
          <Box display="flex" alignItems="center">
            <FaSearch style={{ marginRight: '8px' }} />
            <FormattedMessage id="description" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <TextField
              value={editingExpense?.description || ''}
              onChange={handleEditChange}
              name="description"
              fullWidth
            />
          ) : (
            expense.description
          )
        ),
      },
      {
        id: 'date',
        label: (
          <Box display="flex" alignItems="center">
            <FaCalendarAlt style={{ marginRight: '8px' }} />
            <FormattedMessage id="date" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <TextField
              type="date"
              value={editingExpense?.date ? format(new Date(editingExpense.date), 'yyyy-MM-dd') : ''}
              onChange={handleEditChange}
              name="date"
              fullWidth
            />
          ) : (
            format(new Date(expense.date), 'dd/MM/yyyy')
          )
        ),
      },
      {
        id: 'amount',
        label: (
          <Box display="flex" alignItems="center">
            <FaEuroSign style={{ marginRight: '8px' }} />
            <FormattedMessage id="amount" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <TextField
              type="number"
              value={editingExpense?.amount || ''}
              onChange={handleEditChange}
              name="amount"
              fullWidth
            />
          ) : (
            `€${expense.amount.toFixed(2)}`
          )
        ),
      },
      {
        id: 'payer',
        label: (
          <Box display="flex" alignItems="center">
            <FaUser style={{ marginRight: '8px' }} />
            <FormattedMessage id="payer" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <Select
              value={editingExpense?.payer || ''}
              onChange={handleEditChange}
              name="payer"
              fullWidth
            >
              {payers.map(payer => (
                <MenuItem key={payer} value={payer}>{payer}</MenuItem>
              ))}
            </Select>
          ) : (
            <CustomChip
              label={expense.payer}
              color={payerColors[expense.payer] || '#E0E0E0'}
              size="small"
            />
          )
        ),
      },
      {
        id: 'type',
        label: (
          <Box display="flex" alignItems="center" justifyContent="center" width="100%">
            <FaExchangeAlt style={{ marginRight: '8px' }} />
            <FormattedMessage id="type" />
          </Box>
        ),
        render: (expense) => (
          <Tooltip title={expense.shared ? `Shared with ${expense.sharedWith}` : expense.isDebt ? `Debt from ${expense.debtFrom}` : 'Regular expense'}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
              {expense.isDebt ? (
                <CreditCard color="error" />
              ) : expense.shared ? (
                <People color="primary" />
              ) : (
                <Person color="action" />
              )}
            </Box>
          </Tooltip>
        ),
      },
      {
        id: 'actions',
        label: <FormattedMessage id="actions" />,
        render: (expense) => (
          <>
            {editingExpense?.id === expense.id ? (
              <>
                <IconButton onClick={handleSaveEdit} size="small">
                  <FaSave />
                </IconButton>
                <IconButton onClick={handleCancelEdit} size="small">
                  <FaTimes />
                </IconButton>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={`upload-file-${expense.id}`}
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor={`upload-file-${expense.id}`}>
                  <IconButton component="span" size="small">
                    <FaUpload />
                  </IconButton>
                </label>
                {editingFile && (
                  <Typography variant="caption" display="block">
                    {editingFile.name}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <IconButton onClick={() => handleEdit(expense)} size="small">
                  <FaEdit />
                </IconButton>
                <IconButton 
                  onClick={() => handleDelete(expense.id)} 
                  size="small"
                  sx={{
                    backgroundColor: 'error.main',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'error.dark',
                    },
                  }}
                >
                  <FaTrash />
                </IconButton>
                {expense.fileUrl && (
                  <IconButton onClick={() => handleOpenReceipt(expense.fileUrl || '')} size="small">
                    <FaFileInvoice />
                  </IconButton>
                )}
              </>
            )}
          </>
        ),
      },
    ]);
  }, [intl, payers, categories, payerColors, editingExpense, editingFile]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setColumns(items);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const paginatedExpenses = useMemo(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedExpenses.slice(startIndex, endIndex);
  }, [sortedExpenses, page]);

  useEffect(() => {
    setTotalPages(Math.ceil(sortedExpenses.length / ITEMS_PER_PAGE));
  }, [sortedExpenses]);

  const handleClearFilters = () => {
    setSelectedPayer('');
    setSelectedCategory('');
    setDescriptionFilter('');
    setSelectedMonth('');
    setSelectedYear('');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="filtered-expenses">
        {/* Removemos a linha abaixo que mostrava o título "Expense History" */}
        {/* <Typography variant="h5" gutterBottom>
          <FormattedMessage id="expenseHistory" />
        </Typography> */}

        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            {/* Primeira linha: Year, Month, Start Date, End Date */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="year-select-label"><FormattedMessage id="year" /></InputLabel>
                <Select
                  labelId="year-select-label"
                  value={selectedYear}
                  label={intl.formatMessage({ id: "year" })}
                  onChange={(e) => setSelectedYear(e.target.value as string)}
                >
                  <MenuItem value=""><FormattedMessage id="allYears" /></MenuItem>
                  {[...Array(5)].map((_, i) => {
                    const year = new Date().getFullYear() - i;
                    return <MenuItem key={year} value={year.toString()}>{year}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="month-select-label"><FormattedMessage id="month" /></InputLabel>
                <Select
                  labelId="month-select-label"
                  value={selectedMonth}
                  label={intl.formatMessage({ id: "month" })}
                  onChange={(e) => setSelectedMonth(e.target.value as string)}
                >
                  <MenuItem value=""><FormattedMessage id="allMonths" /></MenuItem>
                  {[...Array(12)].map((_, i) => (
                    <MenuItem key={i + 1} value={(i + 1).toString()}>
                      {intl.formatMessage({ id: `month${i + 1}` })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label={intl.formatMessage({ id: "startDate" })}
                value={startDateState}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                  />
                )}
                inputFormat="dd/MM/yyyy"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label={intl.formatMessage({ id: "endDate" })}
                value={endDateState}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                  />
                )}
                inputFormat="dd/MM/yyyy"
              />
            </Grid>

            {/* Segunda linha: Payer e Category */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="payer-select-label"><FormattedMessage id="payer" /></InputLabel>
                <Select
                  labelId="payer-select-label"
                  value={selectedPayer}
                  label={intl.formatMessage({ id: "payer" })}
                  onChange={(e) => setSelectedPayer(e.target.value as string)}
                >
                  <MenuItem value=""><FormattedMessage id="allPayers" /></MenuItem>
                  {payers.map(payer => (
                    <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="category-select-label"><FormattedMessage id="category" /></InputLabel>
                <Select
                  labelId="category-select-label"
                  value={selectedCategory}
                  label={intl.formatMessage({ id: "category" })}
                  onChange={(e) => setSelectedCategory(e.target.value as string)}
                >
                  <MenuItem value=""><FormattedMessage id="allCategories" /></MenuItem>
                  {sortedCategories.map(category => (
                    <MenuItem key={category} value={category}>
                      {categoryEmojis[category] || ''} {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Terceira linha: Search e botões */}
            <Grid item xs={12} container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  fullWidth
                  size="small"
                  label={intl.formatMessage({ id: "searchDescription" })}
                  value={descriptionFilter}
                  onChange={(e) => setDescriptionFilter(e.target.value)}
                  InputProps={{
                    startAdornment: <FaSearch style={{ marginRight: '8px' }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<FaEraser />}
                    onClick={handleClearFilters}
                    size="small"
                  >
                    <FormattedMessage id="clearFilters" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FaFileExport />}
                    onClick={exportToCSV}
                    size="small"
                  >
                    <FormattedMessage id="exportCSV" />
                  </Button>
                </Grid>
                <Grid item>
                  <Tooltip title={<FormattedMessage id="refreshData" />}>
                    <IconButton onClick={fetchExpenses} color="primary" size="small">
                      <FaSync />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper 
          elevation={2} 
          sx={{ 
            p: 2, 
            mb: 2, 
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            color: 'white'
          }}
        >
          <Box display="flex" flexDirection="column" justifyContent="flex-start">
            <Typography variant="h5" fontWeight="bold">
              <FormattedMessage id="total" />: €{totalExpenses.toFixed(2)}
            </Typography>
            {calculatePayerTotals && (
              <Typography variant="subtitle1">
                <FormattedMessage id="totalAfterDeductions" values={{ payer: selectedPayer }} />: 
                €{calculatePayerTotals.totalAfterDeductions.toFixed(2)}
              </Typography>
            )}
          </Box>
        </Paper>

        <TableContainer sx={{ flexGrow: 1, overflow: 'auto' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} sx={{ fontWeight: 'bold' }}>
                    <Box display="flex" alignItems="center">
                      {column.label}
                      {column.id !== 'actions' && (
                        <IconButton size="small" onClick={() => requestSort(column.id as SortableExpenseKey)}>
                          {sortConfig?.key === column.id ? (
                            sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                          ) : (
                            <FaSort />
                          )}
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedExpenses.length > 0 ? (
                paginatedExpenses.map(expense => (
                  <TableRow key={expense.id}>
                    {columns.map(column => (
                      <TableCell key={column.id}>
                        {column.id === 'actions' ? (
                          <>
                            <IconButton onClick={() => handleEdit(expense)} size="small">
                              <FaEdit />
                            </IconButton>
                            <IconButton 
                              onClick={() => handleDelete(expense.id)} 
                              size="small"
                              sx={{
                                backgroundColor: 'error.main',
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: 'error.dark',
                                },
                              }}
                            >
                              <FaTrash />
                            </IconButton>
                            {expense.fileUrl && (
                              <IconButton onClick={() => handleOpenReceipt(expense.fileUrl || '')} size="small">
                                <FaFileInvoice />
                              </IconButton>
                            )}
                          </>
                        ) : (
                          column.render(
                            expense,
                            editingExpense?.id === expense.id,
                            editingExpense,
                            handleEditChange,
                            handleSharedChange
                          )
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <FormattedMessage id="noExpensesFound" />
                    {/* Adicione mais informações para depuração */}
                    <Typography variant="caption" display="block">
                      Fetched: {fetchedExpenses.length}, Filtered: {filteredExpenses.length}, Sorted: {sortedExpenses.length}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={2} display="flex" justifyContent="center">
          <Pagination 
            count={totalPages} 
            page={page} 
            onChange={handlePageChange}
            color="primary"
            size="large"
          />
        </Box>

        <Dialog open={openReceiptDialog} onClose={handleCloseReceipt} maxWidth="md" fullWidth>
          <DialogTitle><FormattedMessage id="receipt" /></DialogTitle>
          <DialogContent>
            {selectedReceipt && (
              <img src={selectedReceipt} alt="Receipt" style={{ width: '100%', height: 'auto' }} />
            )}
          </DialogContent>
        </Dialog>

        <Dialog open={editDialogOpen} onClose={handleCancelEdit} maxWidth="md" fullWidth>
          <DialogTitle><FormattedMessage id="editExpense" /></DialogTitle>
          <DialogContent>
            {editingExpense && (
              <Box component="form" sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  label={<FormattedMessage id="description" />}
                  name="description"
                  value={editingExpense.description}
                  onChange={handleEditChange}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label={<FormattedMessage id="amount" />}
                  name="amount"
                  type="number"
                  value={editingExpense.amount}
                  onChange={handleEditChange}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label={<FormattedMessage id="date" />}
                  name="date"
                  type="date"
                  value={format(new Date(editingExpense.date), 'yyyy-MM-dd')}
                  onChange={handleEditChange}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel><FormattedMessage id="category" /></InputLabel>
                  <Select
                    name="category"
                    value={editingExpense.category}
                    onChange={handleEditChange}
                  >
                    {categories.map(cat => (
                      <MenuItem key={cat} value={cat}>
                        {categoryEmojis[cat] || ''} {cat}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel><FormattedMessage id="payer" /></InputLabel>
                  <Select
                    name="payer"
                    value={editingExpense.payer}
                    onChange={handleEditChange}
                  >
                    {payers.map(payer => (
                      <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* Adicione mais campos conforme necessário */}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelEdit} color="secondary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={handleSaveEdit} color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default FilteredExpenses;