import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, Button, Box, Typography, InputLabel, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, IconButton, Tooltip, Snackbar, CircularProgress } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Add as AddIcon, Save as SaveIcon, Cancel as CancelIcon, Edit as EditIcon, Delete as DeleteIcon, Settings as SettingsIcon, Attachment as AttachmentIcon } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Income } from '../types/Income';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useIntl, FormattedMessage } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';
import { BankAccount } from '../types/BankAccount';
import { format } from 'date-fns';

interface IncomeFormProps {
  onAddIncome: (income: Omit<Income, 'id' | 'userId'>, file?: File) => Promise<void>;
  categories: string[];
  onAddCategory: (category: string) => void;
  onEditCategory: (oldCategory: string, newCategory: string) => void;
  initialIncome?: Income;
  bankAccounts: BankAccount[];
  onUpdateBankAccount: (accountId: string, newBalance: number) => Promise<void>;
}

// Add this constant at the top of the file, outside the component
const incomeCategoryEmojis: { [key: string]: string } = {
  'Trabalho': '💼',
  'Freelance': '🆓',
  'Investimentos': '📈',
  'Presentes': '🎁',
  'Reembolsos': '💸',
  'Extras': '➕',
  // Add more income categories and emojis as needed
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IncomeForm: React.FC<IncomeFormProps> = ({ onAddIncome, categories, onAddCategory, onEditCategory, initialIncome, bankAccounts, onUpdateBankAccount }) => {
  const intl = useIntl();
  const [income, setIncome] = useState<Income>(() => ({
    id: initialIncome?.id || '',
    userId: initialIncome?.userId || '',
    description: initialIncome?.description || '',
    date: initialIncome?.date || new Date().toISOString().split('T')[0],
    amount: initialIncome?.amount || 0,
    category: initialIncome?.category || '',
    source: initialIncome?.source || '',
    payer: initialIncome?.payer || 'Bruno',
    token: initialIncome?.token || '',
    bankAccountId: initialIncome?.bankAccountId || '',
  }));
  const [amountInput, setAmountInput] = useState(initialIncome ? initialIncome.amount.toFixed(2) : '');
  const [newCategory, setNewCategory] = useState('');
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [sortedCategories, setSortedCategories] = useState<string[]>([]);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [editingCategoryName, setEditingCategoryName] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBank, setSelectedBank] = useState<string>(initialIncome?.bankAccountId || '');

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setSortedCategories([...categories].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })));
      const savedSuggestions = localStorage.getItem('incomeDescriptionSuggestions');
      if (savedSuggestions) {
        setDescriptionSuggestions(JSON.parse(savedSuggestions));
      }
      setIsLoading(false);
    };

    loadData();
  }, [categories]);

  useEffect(() => {
    if (initialIncome) {
      setIncome(initialIncome);
      setAmountInput(initialIncome.amount.toFixed(2));
      setSelectedBank(initialIncome.bankAccountId);
    }
  }, [initialIncome]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    console.log(`Updating field: ${name} with value: ${value}`);
    setIncome(prev => {
      const newIncome = { ...prev, [name]: value };
      console.log('New income state:', newIncome);
      return newIncome;
    });
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(',', '.');
    if (/^\d*[.,]?\d{0,2}$/.test(sanitizedValue)) {
      setAmountInput(sanitizedValue);
      setIncome(prev => ({ ...prev, amount: parseFloat(sanitizedValue) || 0 }));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    console.log('Submitting form with data:', JSON.stringify(income, null, 2), 'Selected bank:', selectedBank);

    const requiredFields = [
      { name: 'description', value: income.description },
      { name: 'date', value: income.date },
      { name: 'category', value: income.category },
      { name: 'amount', value: income.amount },
      { name: 'source', value: income.source },
      { name: 'bankAccountId', value: selectedBank },
    ];

    console.log('Required fields:', requiredFields);

    const emptyFields = requiredFields.filter(field => {
      const isEmpty = !field.value && field.value !== 0;
      console.log(`Field ${field.name}: value = ${field.value}, isEmpty = ${isEmpty}`);
      return isEmpty;
    });

    console.log('Empty fields:', emptyFields);

    if (emptyFields.length > 0 || income.amount <= 0) {
      console.log('Validation failed. Empty fields:', emptyFields.map(field => field.name));
      console.log('Amount:', income.amount);
      setError(intl.formatMessage({ id: 'fillAllFields' }, { fields: emptyFields.map(field => field.name).join(', ') }));
      return;
    }

    try {
      const newIncome: Omit<Income, 'id' | 'userId'> = {
        description: income.description,
        date: income.date,
        payer: income.payer,
        category: income.category,
        amount: parseFloat(formatAmount(parseFloat(amountInput))),
        source: income.source,
        bankAccountId: selectedBank,
        token: income.token,
      };

      console.log('Submitting new income:', newIncome);

      await onAddIncome(newIncome, selectedFile || undefined);

      // Resetar o formulário apenas se não estiver no modo de edição
      if (!initialIncome) {
        setIncome({
          id: '',
          userId: '',
          description: '',
          date: new Date().toISOString().split('T')[0],
          amount: 0,
          category: '',
          source: '',
          payer: 'Bruno',
          bankAccountId: '',
          token: '',
        });
        setSelectedBank('');
        setAmountInput('');
        setSelectedFile(null);
      }
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error adding/editing income:', error);
      setError(intl.formatMessage({ id: 'errorAddingIncome' }));
    }
  };

  const handleAddCategory = () => {
    const trimmedCategory = newCategory.trim();
    if (trimmedCategory !== '') {
      if (!categories.includes(trimmedCategory)) {
        onAddCategory(trimmedCategory);
        setIsAddingCategory(false);
        setIncome({...income, category: trimmedCategory});
        setNewCategory('');
      } else {
        alert(`${intl.formatMessage({ id: 'categoryExists' })} "${trimmedCategory}".`);
      }
    }
  };

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
    setEditingCategoryName('');
    setNewCategoryName('');
  };

  const handleEditCategoryStart = (category: string) => {
    setEditingCategoryName(category);
    setNewCategoryName(category);
  };

  const handleEditCategoryConfirm = () => {
    if (newCategoryName.trim() !== '' && newCategoryName !== editingCategoryName) {
      onEditCategory(editingCategoryName, newCategoryName.trim());
      setEditingCategoryName('');
      setNewCategoryName('');
      // Atualizar a categoria do income se for a categoria que está sendo editada
      if (income.category === editingCategoryName) {
        setIncome(prev => ({ ...prev, category: newCategoryName.trim() }));
      }
    }
  };

  const handleDeleteCategoryConfirm = (category: string) => {
    if (window.confirm(`${intl.formatMessage({ id: 'confirmDelete' })} "${category}"?`)) {
      // Implement category deletion logic here
    }
  };

  const handleDateChange = (newValue: Date | null) => {
    if (newValue instanceof Date) {
      // Ensure the date is not affected by timezone
      const localDate = new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000);
      setIncome(prev => ({ ...prev, date: localDate.toISOString().split('T')[0] }));
    } else if (newValue === null) {
      setIncome(prev => ({ ...prev, date: '' }));
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    if (value !== null) {
      console.log(`Updating description with value: ${value}`);
      setIncome(prev => {
        const newIncome = { ...prev, description: value };
        console.log('New income state after description update:', newIncome);
        return newIncome;
      });
    }
  };

  const formatAmount = (value: number): string => {
    return value.toFixed(2);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
          {error && <Typography color="error">{error}</Typography>}
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="category-label"><FormattedMessage id="category" /></InputLabel>
              <Select
                labelId="category-label"
                label={intl.formatMessage({ id: 'category' })}
                name="category"
                value={income.category}
                onChange={handleChange}
                required
              >
                <MenuItem value=""><FormattedMessage id="selectCategory" /></MenuItem>
                {sortedCategories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {incomeCategoryEmojis[category] || ''} {category}
                  </MenuItem>
                ))}
                <MenuItem value="new">+ <FormattedMessage id="addNewCategory" /></MenuItem>
              </Select>
            </FormControl>
            <Tooltip title={intl.formatMessage({ id: 'manageCategories' })}>
              <IconButton
                size="small"
                onClick={handleOpenCategoryDialog}
                sx={{ ml: 1 }}
              >
                <SettingsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>

          {isAddingCategory && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label={intl.formatMessage({ id: 'newCategory' })}
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                fullWidth
              />
              <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddCategory}>
                <FormattedMessage id="add" />
              </Button>
            </Box>
          )}
          
          <Autocomplete
            freeSolo
            options={descriptionSuggestions}
            value={income.description}
            onChange={handleDescriptionChange}
            onInputChange={(event, newInputValue) => {
              handleDescriptionChange(event, newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'description' })}
                name="description"
                required
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable browser autocomplete
                }}
              />
            )}
          />
          
          <DatePicker
            label={intl.formatMessage({ id: 'date' })}
            value={income.date ? new Date(income.date) : null}
            onChange={(newValue) => handleDateChange(newValue as Date | null)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps?.endAdornment}
                    </React.Fragment>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    cursor: 'pointer',
                  },
                  '& .MuiInputBase-input': {
                    cursor: 'pointer',
                  },
                }}
                onClick={(event) => {
                  if (params.inputProps?.onClick) {
                    (params.inputProps.onClick as React.MouseEventHandler<HTMLDivElement>)(event);
                  }
                }}
              />
            )}
            inputFormat="dd/MM/yyyy"
          />
          
          <TextField
            label={intl.formatMessage({ id: 'source' })}
            name="source"
            value={income.source}
            onChange={handleChange}
            required
            fullWidth
          />
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              type="text"
              label={`${intl.formatMessage({ id: 'amount' })} (€)`}
              name="amount"
              value={amountInput}
              onChange={handleAmountChange}
              required
              fullWidth
              InputProps={{ 
                style: { textAlign: 'right' },
              }}
              inputProps={{
                inputMode: 'decimal',
                pattern: '[0-9]*[.,]?[0-9]*'
              }}
              onKeyPress={(e) => {
                const pattern = /[0-9.,]/;
                if (!pattern.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            
            <FormControl fullWidth>
              <InputLabel id="bank-account-label">{intl.formatMessage({ id: 'bankAccount' })}</InputLabel>
              <Select
                labelId="bank-account-label"
                label={intl.formatMessage({ id: 'bankAccount' })}
                value={selectedBank}
                onChange={(e) => setSelectedBank(e.target.value)}
                required
              >
                <MenuItem value=""><FormattedMessage id="selectBank" /></MenuItem>
                {bankAccounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {`${account.bankName} - ${account.accountName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box>
            <input
              accept="image/*,application/pdf"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button variant="outlined" component="span" startIcon={<AttachmentIcon />}>
                <FormattedMessage id="attachReceipt" />
              </Button>
            </label>
            {selectedFile && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {selectedFile.name}
              </Typography>
            )}
          </Box>

          <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
            <FormattedMessage id="addIncome" />
          </Button>
        </Box>

        <Dialog open={openCategoryDialog} onClose={handleCloseCategoryDialog}>
          <DialogTitle><FormattedMessage id="manageCategories" /></DialogTitle>
          <DialogContent>
            <List>
              {sortedCategories.map((category) => (
                <ListItem key={category}>
                  {editingCategoryName === category ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                      <TextField
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        fullWidth
                      />
                      <IconButton onClick={handleEditCategoryConfirm}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton onClick={() => setEditingCategoryName('')}>
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <ListItemText primary={`${incomeCategoryEmojis[category] || ''} ${category}`} />
                      <IconButton onClick={() => handleEditCategoryStart(category)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteCategoryConfirm(category)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCategoryDialog}><FormattedMessage id="close" /></Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            <FormattedMessage id="incomeSubmittedSuccessfully" />
          </Alert>
        </Snackbar>
      </motion.div>
    </LocalizationProvider>
  );
};

export default IncomeForm;