import React, { useState, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, TableSortLabel } from '@mui/material';
import { Edit, Delete, DateRange, Description, AttachMoney, Category, Source, AccountBalance } from '@mui/icons-material';
import { Income } from '../types/Income';
import { FormattedMessage, useIntl } from 'react-intl';
import IncomeForm from './IncomeForm';
import { BankAccount } from '../types/BankAccount';

interface IncomeListProps {
  income: Income[];
  onEditIncome: (updatedIncome: Income) => Promise<void>;
  onDeleteIncome: (id: string) => Promise<void>;
  categories: string[];
  onAddCategory: (category: string) => void;
  onEditCategory: (oldCategory: string, newCategory: string) => void;
  bankAccounts: BankAccount[];
  onUpdateBankAccount: (accountId: string, newBalance: number) => Promise<void>;
}

type SortField = 'date' | 'description' | 'amount' | 'category' | 'source' | 'bankAccount';
type SortOrder = 'asc' | 'desc';

const IncomeList: React.FC<IncomeListProps> = ({ income, onEditIncome, onDeleteIncome, categories, onAddCategory, onEditCategory, bankAccounts, onUpdateBankAccount }) => {
  const [editingIncome, setEditingIncome] = useState<Income | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<SortField>('date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const intl = useIntl();

  const handleEditClick = (incomeItem: Income) => {
    setEditingIncome({...incomeItem});
  };

  const handleEditClose = () => {
    setEditingIncome(null);
  };

  const handleEditSubmit = async (updatedIncome: Omit<Income, 'id' | 'userId'>): Promise<void> => {
    if (editingIncome) {
      const fullUpdatedIncome: Income = {
        ...updatedIncome,
        id: editingIncome.id,
        userId: editingIncome.userId
      };
      await onEditIncome(fullUpdatedIncome);
      setEditingIncome(null);
    }
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const getBankAccountName = (bankAccountId: string) => {
    const account = bankAccounts.find(acc => acc.id === bankAccountId);
    return account ? `${account.bankName} - ${account.accountName}` : 'N/A';
  };

  const sortedIncome = useMemo(() => {
    return [...income]
      .filter(entry =>
        entry.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.source.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getBankAccountName(entry.bankAccountId).toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        if (sortField === 'bankAccount') {
          const aValue = getBankAccountName(a.bankAccountId);
          const bValue = getBankAccountName(b.bankAccountId);
          if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
          return 0;
        }
        const aValue = a[sortField];
        const bValue = b[sortField];
        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
  }, [income, searchTerm, sortField, sortOrder, bankAccounts]);

  return (
    <>
      <TableContainer component={Paper}>
        <Typography variant="h6" sx={{ p: 2 }}><FormattedMessage id="incomeList" /></Typography>
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            label={<FormattedMessage id="search" />}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'date'}
                  direction={sortField === 'date' ? sortOrder : 'asc'}
                  onClick={() => handleSort('date')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DateRange sx={{ mr: 1 }} />
                    <FormattedMessage id="date" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'description'}
                  direction={sortField === 'description' ? sortOrder : 'asc'}
                  onClick={() => handleSort('description')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Description sx={{ mr: 1 }} />
                    <FormattedMessage id="description" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'amount'}
                  direction={sortField === 'amount' ? sortOrder : 'asc'}
                  onClick={() => handleSort('amount')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>€</Typography>
                    <FormattedMessage id="amount" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'category'}
                  direction={sortField === 'category' ? sortOrder : 'asc'}
                  onClick={() => handleSort('category')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Category sx={{ mr: 1 }} />
                    <FormattedMessage id="category" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'source'}
                  direction={sortField === 'source' ? sortOrder : 'asc'}
                  onClick={() => handleSort('source')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Source sx={{ mr: 1 }} />
                    <FormattedMessage id="source" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'bankAccount'}
                  direction={sortField === 'bankAccount' ? sortOrder : 'asc'}
                  onClick={() => handleSort('bankAccount')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccountBalance sx={{ mr: 1 }} />
                    <FormattedMessage id="bankAccount" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <FormattedMessage id="actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedIncome.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{new Date(entry.date).toLocaleDateString()}</TableCell>
                <TableCell>{entry.description}</TableCell>
                <TableCell>{`${entry.amount.toFixed(2)} €`}</TableCell>
                <TableCell>{entry.category}</TableCell>
                <TableCell>{entry.source}</TableCell>
                <TableCell>{getBankAccountName(entry.bankAccountId)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(entry)}>
                    <Edit />
                  </IconButton>
                  <IconButton 
                    onClick={() => onDeleteIncome(entry.id)}
                    sx={{
                      backgroundColor: 'error.main',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'error.dark',
                      },
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={!!editingIncome} onClose={handleEditClose} maxWidth="md" fullWidth>
        <DialogTitle><FormattedMessage id="editIncome" /></DialogTitle>
        <DialogContent>
          {editingIncome && (
            <IncomeForm
              onAddIncome={handleEditSubmit}
              categories={categories}
              onAddCategory={onAddCategory}
              onEditCategory={onEditCategory}
              initialIncome={editingIncome}
              bankAccounts={bankAccounts}
              onUpdateBankAccount={onUpdateBankAccount}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}><FormattedMessage id="cancel" /></Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IncomeList;