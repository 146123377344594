import React, { useState, useMemo } from 'react';
import { Card, CardContent, Typography, Button, Grid, Chip, Box } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, TooltipProps } from 'recharts';
import { Expense } from '../types/Expense';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';

interface CategoryExpenseChartProps {
  expenses: Expense[];
  selectedYear: number;
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'];

const CategoryExpenseChart: React.FC<CategoryExpenseChartProps> = ({ expenses, selectedYear }) => {
  const categories = useMemo(() => {
    const uniqueCategories = Array.from(new Set(expenses.map(e => e.category)));
    return uniqueCategories.sort((a, b) => a.localeCompare(b));
  }, [expenses]);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const chartData = useMemo(() => {
    const monthlyData = MONTHS.map(month => ({ name: month } as { [key: string]: string | number }));
    const ytdTotals: { [key: string]: number } = {};
    const ytdCounts: { [key: string]: number } = {};

    categories.forEach((category) => {
      ytdTotals[category] = 0;
      ytdCounts[category] = 0;

      MONTHS.forEach((_, monthIndex) => {
        const monthlyExpenses = expenses.filter(e => 
          new Date(e.date).getFullYear() === selectedYear &&
          new Date(e.date).getMonth() === monthIndex &&
          e.category === category
        );
        const totalAmount = monthlyExpenses.reduce((sum, e) => sum + e.amount, 0);
        monthlyData[monthIndex][category] = totalAmount;

        // Calculate YTD totals and counts
        if (totalAmount > 0) {
          ytdTotals[category] += totalAmount;
          ytdCounts[category]++;
        }
      });

      // Calculate and add YTD average to each month's data
      monthlyData.forEach(monthData => {
        monthData[`${category}Avg`] = ytdCounts[category] > 0 
          ? ytdTotals[category] / ytdCounts[category] 
          : 0;
      });
    });

    return monthlyData;
  }, [expenses, selectedYear, categories]);

  const handleCategoryToggle = (category: string) => {
    setSelectedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handleSelectAll = () => {
    setSelectedCategories(categories);
  };

  const handleDeselectAll = () => {
    setSelectedCategories([]);
  };

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    const theme = useTheme();
    
    if (active && payload && payload.length) {
      // Group regular values and averages
      const groupedPayload = payload.reduce((acc, entry) => {
        if (entry.name) {
          const isAvg = entry.name.endsWith('Avg');
          const category = isAvg ? entry.name.replace('Avg', '') : entry.name;
          
          if (!acc[category]) {
            acc[category] = { regular: null, avg: null };
          }
          
          if (isAvg) {
            acc[category].avg = entry;
          } else {
            acc[category].regular = entry;
          }
        }
        return acc;
      }, {} as Record<string, { regular: any; avg: any }>);

      return (
        <Box
          sx={{
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.95)',
            padding: '16px',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '12px',
            boxShadow: theme.shadows[4],
            maxWidth: '300px',
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ 
            borderBottom: `2px solid ${theme.palette.primary.main}`, 
            paddingBottom: '8px', 
            marginBottom: '12px',
            color: theme.palette.primary.main
          }}>
            {label}
          </Typography>
          {Object.entries(groupedPayload).map(([category, { regular, avg }]) => (
            <Box key={category} sx={{ 
              mb: 1,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                borderRadius: '4px',
              },
              transition: 'background-color 0.3s',
              padding: '4px 8px',
            }}>
              {regular && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        backgroundColor: regular.color,
                        marginRight: 1.5,
                        border: `2px solid ${theme.palette.background.paper}`,
                        boxShadow: theme.shadows[1],
                      }}
                    />
                    <Typography variant="body2" sx={{ 
                      color: theme.palette.text.primary,
                      fontWeight: 'bold',
                    }}>
                      {category}
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ 
                    fontWeight: 'bold', 
                    color: regular.color,
                  }}>
                    €{regular.value?.toFixed(2)}
                  </Typography>
                </Box>
              )}
              {avg && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pl: 3, mt: 0.5 }}>
                  <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                    YTD Avg:
                  </Typography>
                  <Typography variant="caption" sx={{ 
                    fontWeight: 'bold',
                    color: theme.palette.text.secondary,
                  }}>
                    €{avg.value?.toFixed(2)}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          <FormattedMessage id="categoryExpenseEvolution" />
        </Typography>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Button onClick={handleSelectAll} variant="outlined" size="small" sx={{ mr: 1 }}>
              <FormattedMessage id="selectAll" />
            </Button>
            <Button onClick={handleDeselectAll} variant="outlined" size="small">
              <FormattedMessage id="deselectAll" />
            </Button>
          </Box>
          <Typography variant="body2">
            <FormattedMessage id="selectedCategories" />: {selectedCategories.length}/{categories.length}
          </Typography>
        </Box>
        <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {categories.map((category, index) => (
            <Chip
              key={category}
              label={category}
              onClick={() => handleCategoryToggle(category)}
              color={selectedCategories.includes(category) ? "primary" : "default"}
            />
          ))}
        </Box>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis 
              tickFormatter={(value) => `€${value}`}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {selectedCategories.map((category, index) => (
              <React.Fragment key={category}>
                <Line
                  type="monotone"
                  dataKey={category}
                  name={`€ ${category}`}
                  stroke={COLORS[index % COLORS.length]}
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey={`${category}Avg`}
                  name={`€ ${category} Avg`}
                  stroke={COLORS[index % COLORS.length]}
                  strokeDasharray="5 5"
                  dot={false}
                />
              </React.Fragment>
            ))}
          </LineChart>
        </ResponsiveContainer>
        {selectedCategories.length === 0 && (
          <Typography align="center" sx={{ mt: 2 }}>
            <FormattedMessage id="selectCategoriesForChart" />
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default CategoryExpenseChart;