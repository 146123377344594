import { openDB, IDBPDatabase } from 'idb';

const DB_NAME = 'FinanceAppCache';
const DB_VERSION = 1;

interface CacheStore {
  key: string;
  value: any;
  timestamp: number;
}

let db: IDBPDatabase | null = null;

const initDB = async () => {
  if (!db) {
    db = await openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        db.createObjectStore('cache', { keyPath: 'key' });
      },
    });
  }
  return db;
};

export const setCache = async (key: string, value: any, expirationTime: number = 3600000) => {
  const db = await initDB();
  const tx = db.transaction('cache', 'readwrite');
  const store = tx.objectStore('cache');
  await store.put({
    key,
    value,
    timestamp: Date.now() + expirationTime,
  });
  await tx.done;
};

export const getCache = async (key: string): Promise<any | null> => {
  const db = await initDB();
  const tx = db.transaction('cache', 'readonly');
  const store = tx.objectStore('cache');
  const result = await store.get(key);
  if (result && result.timestamp > Date.now()) {
    return result.value;
  }
  return null;
};

export const clearCache = async () => {
  const db = await initDB();
  const tx = db.transaction('cache', 'readwrite');
  const store = tx.objectStore('cache');
  await store.clear();
  await tx.done;
};