import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import ptMessages from './locales/pt-PT.json';
import enMessages from './locales/en-US.json';
import App from './App';

const messages: { [key: string]: any } = {
  'pt-PT': ptMessages,
  'en-US': enMessages,
};

const AppWrapper: React.FC = () => {
  const [locale, setLocale] = useState<'pt-PT' | 'en-US'>('en-US');

  return (
    <IntlProvider messages={messages[locale]} locale={locale}>
      <App locale={locale} setLocale={setLocale} />
    </IntlProvider>
  );
};

export default AppWrapper;