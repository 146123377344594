import React, { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, Button, Paper, Box, IconButton, Grid, Card, CardContent, Divider, CircularProgress, CardHeader, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Save as SaveIcon, Cancel as CancelIcon, TrendingDown as TrendingDownIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { AccountBalance as AccountBalanceIcon, TrendingUp as TrendingUpIcon } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import { addBankAccount, updateBankAccount, deleteBankAccount, getBankAccounts } from '../firebase/bankAccountOperations';
import { useAuth } from '../contexts/AuthContext';
import { BankAccount } from '../types/BankAccount';
import bankinterLogo from '../assets/bankinter.png';
import revolutLogo from '../assets/revolut.png';
import santanderLogo from '../assets/santander.png';
import pluxeeLogo from '../assets/pluxee.png';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { setCache, getCache } from '../utils/indexedDB';

interface BankAccountProps {
  onUpdateBalance: (accountId: string, newBalance: number) => Promise<void>;
  accounts: BankAccount[];
  loading: boolean;
  onFetchAccounts: () => Promise<void>;
}

const CACHE_DURATION = 10 * 60 * 1000; // 10 minutos em milissegundos

const BankAccountComponent: React.FC<BankAccountProps> = ({ 
  onUpdateBalance, 
  accounts, 
  loading, 
  onFetchAccounts 
}) => {
  const [newAccount, setNewAccount] = useState<Omit<BankAccount, 'id' | 'userId' | 'lastUpdated'>>({ 
    bankName: '', 
    accountName: '', 
    balance: 0 
  });
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingAccount, setEditingAccount] = useState<BankAccount | null>(null);
  const [lastFetch, setLastFetch] = useState<number | null>(null);
  const [showValues, setShowValues] = useState(false);
  const intl = useIntl();
  const { user } = useAuth();

  const fetchAccounts = useCallback(async () => {
    await onFetchAccounts();
  }, [onFetchAccounts]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const handleAddAccount = async () => {
    if (newAccount.bankName.trim() === '' || newAccount.accountName.trim() === '') {
      alert(intl.formatMessage({ id: 'bankAccount.fieldsRequired' }));
      return;
    }
    if (user) {
      try {
        const accountToAdd: Omit<BankAccount, 'id'> = {
          ...newAccount,
          userId: user.uid,
          lastUpdated: new Date().getTime(), // Alterado aqui
        };
        await addBankAccount(accountToAdd, user);
        await fetchAccounts();
        setNewAccount({ bankName: '', accountName: '', balance: 0 });
      } catch (error) {
        console.error('Error adding bank account:', error);
        alert(intl.formatMessage({ id: 'bankAccount.addError' }));
      }
    }
  };

  const handleEditAccount = async (account: BankAccount) => {
    if (user) {
      try {
        const updatedAccount = {
          ...account,
          lastUpdated: new Date().getTime(), // Alterado aqui
        };
        await updateBankAccount(updatedAccount, user);
        await fetchAccounts();
        setEditingId(null);
      } catch (error) {
        console.error('Error updating bank account:', error);
        alert(intl.formatMessage({ id: 'bankAccount.updateError' }));
      }
    }
  };

  const handleDeleteAccount = async (id: string) => {
    if (window.confirm(intl.formatMessage({ id: 'bankAccount.confirmDelete' }))) {
      if (user) {
        try {
          await deleteBankAccount(id, user);
          await fetchAccounts();
        } catch (error) {
          console.error('Error deleting bank account:', error);
          alert(intl.formatMessage({ id: 'bankAccount.deleteError' }));
        }
      }
    }
  };

  const handleUpdateBalance = async (accountId: string, newBalance: number) => {
    try {
      await onUpdateBalance(accountId, newBalance);
      // Não precisamos atualizar o estado local aqui, pois o componente pai (App) 
      // irá atualizar as contas e passar as novas props
      console.log(`Balance updated for account ${accountId}`);
      // Opcionalmente, podemos chamar onFetchAccounts para atualizar todas as contas
      await onFetchAccounts();
    } catch (error) {
      console.error('Error updating balance:', error);
      // Você pode adicionar uma notificação de erro aqui
    }
  };

  const totalBalance = accounts.reduce((sum, account) => sum + account.balance, 0);

  const getCardGradient = (bankName: string) => {
    switch (bankName.toLowerCase()) {
      case 'bankinter':
        return 'linear-gradient(135deg, #FF6600 0%, #FF9900 100%)';
      case 'santander':
        return 'linear-gradient(135deg, #EC0000 0%, #FF3333 100%)';
      case 'revolut':
        return 'linear-gradient(135deg, #0666EB 0%, #33A9FF 100%)';
      case 'pluxee':
        return 'linear-gradient(135deg, #32CD32 0%, #7CFC00 100%)'; // Verde lima gradiente para Pluxee
      default:
        return 'linear-gradient(135deg, #FE6B8B 30%, #FF8E53 90%)';
    }
  };

  const getBankLogo = (bankName: string): { src: string; width: number; height: number } => {
    switch (bankName.toLowerCase()) {
      case 'bankinter':
        return { src: bankinterLogo, width: 60, height: 40 };
      case 'revolut':
        return { src: revolutLogo, width: 60, height: 40 };
      case 'santander':
        return { src: santanderLogo, width: 120, height: 80 }; // Dobro do tamanho original
      case 'pluxee':
        return { src: pluxeeLogo, width: 60, height: 40 }; // Adicionado o caso para Pluxee
      default:
        return { src: '', width: 60, height: 40 };
    }
  };

  const handleEditClick = (account: BankAccount) => {
    setEditingId(account.id);
    setEditingAccount(account);
  };

  const handleEditClose = () => {
    setEditingId(null);
    setEditingAccount(null);
  };

  const handleEditSave = async () => {
    if (editingAccount && user) {
      try {
        await updateBankAccount(editingAccount, user);
        await fetchAccounts();
        handleEditClose();
      } catch (error) {
        console.error('Error updating bank account:', error);
        alert(intl.formatMessage({ id: 'bankAccount.updateError' }));
      }
    }
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editingAccount) {
      setEditingAccount({
        ...editingAccount,
        [e.target.name]: e.target.name === 'balance' ? parseFloat(e.target.value) : e.target.value,
      });
    }
  };

  const toggleValuesVisibility = () => {
    setShowValues(!showValues);
  };

  const formatBalance = (balance: number) => {
    return `€${balance.toFixed(2)}`;
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        flexDirection: 'column'
      }}>
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', mt: 4 }}>
      {/* Total Balance Card */}
      <Card sx={{ 
        mb: 4, 
        background: 'linear-gradient(135deg, #0A2647 0%, #144272 100%)', 
        color: 'white',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      }}>
        <CardContent sx={{ py: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AccountBalanceIcon sx={{ fontSize: 40, mr: 2 }} />
            <Box>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                <FormattedMessage id="bankAccount.totalBalance" />
              </Typography>
              <Typography 
                variant="h4" 
                sx={{ 
                  mt: 1, 
                  fontWeight: 'bold',
                  filter: showValues ? 'none' : 'blur(10px)', // Aumentado para 10px
                  transition: 'filter 0.3s ease'
                }}
              >
                {formatBalance(accounts.reduce((sum, account) => sum + account.balance, 0))}
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={toggleValuesVisibility} sx={{ color: 'white' }}>
            {showValues ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </CardContent>
      </Card>

      {/* Bank Accounts List */}
      <Grid container spacing={3}>
        {accounts.map((account) => (
          <Grid item xs={12} sm={6} md={4} key={account.id}>
            <Card sx={{ 
              width: '340px',
              height: '215px',
              display: 'flex',
              flexDirection: 'column',
              background: getCardGradient(account.bankName),
              color: 'white',
              boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
              borderRadius: '10px',
              position: 'relative',
              overflow: 'hidden',
            }}>
              <Box sx={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                width: '120px', // Aumentado para acomodar o logo maior do Santander
                height: '80px', // Aumentado para acomodar o logo maior do Santander
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden', // Adicionado para evitar que o logo maior afete o layout
              }}>
                <Box
                  component="img"
                  src={getBankLogo(account.bankName).src}
                  alt={account.bankName}
                  sx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    filter: 'brightness(0) invert(1)',
                    opacity: 0.9,
                  }}
                />
              </Box>
              <CardContent sx={{ 
                flexGrow: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'space-between',
                padding: '20px',
              }}>
                <Typography 
                  variant="h4" 
                  component="div" 
                  sx={{ 
                    textAlign: 'right', 
                    mt: 2,
                    filter: showValues ? 'none' : 'blur(10px)', // Aumentado para 10px
                    transition: 'filter 0.3s ease'
                  }}
                >
                  {formatBalance(account.balance)}
                </Typography>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {account.accountName}
                  </Typography>
                  <Typography variant="caption" sx={{ display: 'block' }}>
                    <FormattedMessage id="bankAccount.lastUpdated" />: {new Date(account.lastUpdated).toLocaleString()}
                  </Typography>
                </Box>
              </CardContent>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'flex-end', 
                padding: '10px',
                position: 'absolute',
                bottom: 0,
                right: 0,
              }}>
                <IconButton onClick={() => handleEditClick(account)} sx={{ color: 'white', padding: 0.5 }}>
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={() => handleDeleteAccount(account.id)} sx={{ color: 'white', padding: 0.5 }}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Edit Account Dialog */}
      <Dialog open={!!editingId} onClose={handleEditClose}>
        <DialogTitle><FormattedMessage id="bankAccount.editAccount" /></DialogTitle>
        <DialogContent>
          {editingAccount && (
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'bankAccount.bankName' })}
                name="bankName"
                value={editingAccount.bankName}
                onChange={handleEditChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'bankAccount.accountName' })}
                name="accountName"
                value={editingAccount.accountName}
                onChange={handleEditChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'bankAccount.balance' })}
                name="balance"
                type="number"
                value={editingAccount.balance}
                onChange={handleEditChange}
                margin="normal"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}><FormattedMessage id="cancel" /></Button>
          <Button onClick={handleEditSave} color="primary"><FormattedMessage id="save" /></Button>
        </DialogActions>
      </Dialog>

      {/* Add New Account Form */}
      <Card sx={{ 
        mt: 4, 
        background: 'linear-gradient(135deg, #2980b9 0%, #2c3e50 100%)',
        color: 'white',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            <AddIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            <FormattedMessage id="bankAccount.addNew" />
          </Typography>
          <Box component="form" onSubmit={(e) => {
            e.preventDefault();
            handleAddAccount();
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={intl.formatMessage({ id: 'bankAccount.bankName' })}
                  value={newAccount.bankName}
                  onChange={(e) => setNewAccount({ ...newAccount, bankName: e.target.value })}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  InputLabelProps={{
                    style: { color: 'rgba(255, 255, 255, 0.7)' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.5)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.7)',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={intl.formatMessage({ id: 'bankAccount.accountName' })}
                  value={newAccount.accountName}
                  onChange={(e) => setNewAccount({ ...newAccount, accountName: e.target.value })}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  InputLabelProps={{
                    style: { color: 'rgba(255, 255, 255, 0.7)' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.5)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.7)',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={intl.formatMessage({ id: 'bankAccount.balance' })}
                  type="number"
                  value={newAccount.balance}
                  onChange={(e) => setNewAccount({ ...newAccount, balance: parseFloat(e.target.value) })}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    style: { color: 'white' },
                    startAdornment: <Typography sx={{ mr: 1 }}>€</Typography>,
                  }}
                  InputLabelProps={{
                    style: { color: 'rgba(255, 255, 255, 0.7)' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.5)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.7)',
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              fullWidth
              sx={{ 
                mt: 2, 
                bgcolor: 'rgba(255, 255, 255, 0.2)', 
                color: 'white',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.3)',
                },
              }}
            >
              <FormattedMessage id="bankAccount.addAccount" />
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BankAccountComponent;