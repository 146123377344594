import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, useMediaQuery, Theme, Paper, Alert, Snackbar, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useIntl, FormattedMessage } from 'react-intl';
import { Investment } from '../types/Investment';
import { motion } from 'framer-motion';
import { Save, Edit, Cancel } from '@mui/icons-material';
import { auth } from '../firebase/config';
import { format } from 'date-fns';

interface InvestmentFormProps {
  onAddInvestment: (investment: Omit<Investment, 'id'>) => Promise<void>;
  onUpdateInvestment: (id: string, investment: Partial<Investment>) => Promise<void>;
  investment?: Investment;
  userId: string;
  onCancel: () => void;
}

const InvestmentForm: React.FC<InvestmentFormProps> = ({ onAddInvestment, onUpdateInvestment, investment, userId, onCancel }) => {
  const [formData, setFormData] = useState<Omit<Investment, 'id'>>({
    userId: userId,
    token: investment?.token || '',
    investment: investment?.investment || '',
    quantity: investment?.quantity || 0,
    purchasePrice: investment?.purchasePrice || 0,
    purchaseDate: investment?.purchaseDate || null,
  });

  const intl = useIntl();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (investment) {
      setFormData({
        userId: userId,
        token: investment.token,
        investment: investment.investment,
        quantity: investment.quantity,
        purchasePrice: investment.purchasePrice,
        purchaseDate: investment.purchaseDate,
      });
    } else {
      setFormData({
        userId: userId,
        token: '',
        investment: '',
        quantity: 0,
        purchasePrice: 0,
        purchaseDate: null,
      });
    }
  }, [investment, userId]);

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      // Adicione aqui qualquer lógica de carregamento necessária
      setIsLoading(false);
    };

    loadData();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numValue = value.replace(',', '.');
    if (!isNaN(parseFloat(numValue)) || numValue === '' || numValue === '.') {
      setFormData(prev => {
        const updatedData = { ...prev, [name]: numValue };
        // Calcular o purchasePrice automaticamente
        if (name === 'investment' || name === 'quantity') {
          const investmentAmount = parseFloat(updatedData.investment) || 0;
          const quantity = parseFloat(updatedData.quantity.toString()) || 1;
          updatedData.purchasePrice = investmentAmount / quantity;
        }
        return updatedData;
      });
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setFormData(prev => ({ ...prev, purchaseDate: date }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);
    setSuccess(false);

    if (!auth.currentUser) {
      setError("You must be logged in to add an investment.");
      return;
    }

    const investmentAmount = parseFloat(formData.investment);
    const quantity = parseFloat(formData.quantity.toString());
    const calculatedPurchasePrice = investmentAmount / quantity;

    const submissionData: Omit<Investment, 'id'> = {
      ...formData,
      quantity: quantity,
      investment: formData.investment,
      purchasePrice: calculatedPurchasePrice,
      purchaseDate: formData.purchaseDate ? formData.purchaseDate : null,
    };

    console.log('Submitting investment data:', submissionData);

    try {
      if (investment) {
        console.log('Updating investment');
        await onUpdateInvestment(investment.id, submissionData);
      } else {
        console.log('Adding new investment');
        await onAddInvestment(submissionData);
      }
      console.log('Investment added/updated successfully');
      setSuccess(true);
      setFormData({
        userId: userId,
        token: '',
        investment: '',
        quantity: 0,
        purchasePrice: 0,
        purchaseDate: null,
      });
    } catch (error) {
      console.error("Error adding/updating investment:", error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred.');
      }
      // Log additional details about the error
      console.error("Error details:", JSON.stringify(error, null, 2));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 2,
            width: '100%',
            padding: '1rem',
          }}
        >
          <TextField
            label={intl.formatMessage({ id: 'token' })}
            name="token"
            value={formData.token}
            onChange={handleChange}
            required
            fullWidth
            size="small"
          />

          <TextField
            label={intl.formatMessage({ id: 'investment' })}
            name="investment"
            type="number"
            inputMode="decimal"
            value={formData.investment}
            onChange={handleNumberChange}
            required
            fullWidth
            size="small"
          />

          <TextField
            label={intl.formatMessage({ id: 'quantity' })}
            name="quantity"
            type="number"
            inputMode="decimal"
            value={formData.quantity}
            onChange={handleNumberChange}
            required
            fullWidth
            size="small"
          />

          <TextField
            label={intl.formatMessage({ id: 'purchasePrice' })}
            name="purchasePrice"
            type="text"
            inputMode="decimal"
            value={formData.purchasePrice.toFixed(2)}
            disabled
            fullWidth
            size="small"
          />

          <DatePicker
            label={intl.formatMessage({ id: 'purchaseDate' })}
            value={formData.purchaseDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} fullWidth size="small" />}
            inputFormat="dd/MM/yyyy"
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button 
              onClick={onCancel}
              variant="outlined" 
              color="error"  // Changed from "secondary" to "error"
              startIcon={<Cancel />}
              size="large"
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              startIcon={investment ? <Edit /> : <Save />}
              size="large"
            >
              {investment ? <FormattedMessage id="updateInvestment" /> : <FormattedMessage id="addInvestment" />}
            </Button>
          </Box>
        </Box>
      </motion.div>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={3000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Investment {investment ? 'updated' : 'added'} successfully!
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default InvestmentForm;