import React, { createContext, useContext, useState, useEffect } from 'react';
import { getInvestments } from '../firebase/firestoreOperations';
import { getMultipleCryptoPrices } from '../services/coingeckoService';
import { auth } from '../firebase/config';
import { Investment as FirestoreInvestment } from '../types/Investment'; // Importe o tipo real

export interface Investment extends Omit<FirestoreInvestment, 'purchaseDate'> {
  purchaseDate: Date | null; // Alterado para aceitar null
}

interface PriceData {
  price: number;
  price_change_percentage_24h: number;
}

export interface InvestmentContextType {
  investments: Investment[];
  currentPrices: { [key: string]: PriceData };
  updateCurrentPrices: (newPrices: { [key: string]: PriceData }) => void;
}

const InvestmentContext = createContext<InvestmentContextType | undefined>(undefined);

export const useInvestments = () => {
  const context = useContext(InvestmentContext);
  if (context === undefined) {
    throw new Error('useInvestments must be used within an InvestmentProvider');
  }
  return context;
};

export const InvestmentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [investments, setInvestments] = useState<Investment[]>([]);
  const [currentPrices, setCurrentPrices] = useState<{ [key: string]: PriceData }>({});

  useEffect(() => {
    const fetchInvestments = async () => {
      if (auth.currentUser) {
        const fetchedInvestments = await getInvestments(auth.currentUser.uid);
        setInvestments(fetchedInvestments as Investment[]); // Use type assertion aqui
        
        const tokens = fetchedInvestments.map(inv => inv.token);
        const prices = await getMultipleCryptoPrices(tokens);
        setCurrentPrices(prices);
      }
    };

    fetchInvestments();
  }, []);

  const updateCurrentPrices = (newPrices: { [key: string]: PriceData }) => {
    setCurrentPrices(newPrices);
  };

  return (
    <InvestmentContext.Provider value={{ investments, currentPrices, updateCurrentPrices }}>
      {children}
    </InvestmentContext.Provider>
  );
};