export const categoryEmojis: { [key: string]: string } = {
  'Água': '💧',
  'Almoços': '🍽️',
  'Benjamim': '👶',
  'Casa': '🏠',
  'Empregada Limpeza': '🧹',
  'Encomendar Comida': '🍕',
  'Extras': '➕',
  'Farmácia': '💊',
  'Gás': '🔥',
  'Investimentos': '💰',
  'IQOS': '🚬',
  'Jantares': '🍷',
  'Luz': '💡',
  'Natação': '🏊‍♂️',
  'Saúde': '🏥',
  'Subscrições': '📝',
  'Supermercado': '🛒',
  'TV & Internet': '📺',
};