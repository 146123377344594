import React, { useState, useEffect, useMemo } from 'react';
import { TextField, Select, MenuItem, FormControlLabel, Switch, Button, Box, Typography, InputLabel, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, IconButton, Tooltip, Input, Checkbox, Snackbar, Autocomplete, CircularProgress, ListItemButton } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SelectChangeEvent } from '@mui/material/Select';
import { Add, Save, Cancel, Edit, Delete, Settings, Attachment, Share, MoneyOff, CreditCard } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Expense } from '../types/Expense';
import { uploadFile } from '../utils/fileUpload';
import { addExpense } from '../firebase/firestoreOperations';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useIntl, FormattedMessage } from 'react-intl';
import { ChromePicker } from 'react-color';
import CustomChip from './CustomChip';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { BankAccount } from '../types/BankAccount';
import { updateBankAccountBalance } from '../firebase/bankAccountOperations';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { categoryEmojis } from '../utils/categoryEmojis';
import { format } from 'date-fns';

interface ExpenseFormProps {
  onAddExpense: (expense: Omit<Expense, 'id' | 'userId'>, file?: File) => Promise<void>;
  categories: string[];
  payers: string[];
  onAddCategory: (category: string) => void;
  onEditCategory: (oldCategory: string, newCategory: string, newEmoji: string) => void;
  onDeleteCategory: (category: string) => void;
  onAddPayer: (payer: string) => void;
  onEditPayer: (oldPayer: string, newPayer: string) => void;
  onDeletePayer: (payer: string) => void;
  onEditPayerColor: (payer: string, color: string) => void;
  payerColors: { [key: string]: string };
  bankAccounts: BankAccount[];
  onUpdateBankAccount: (accountId: string, newBalance: number) => Promise<void>;
  onExpenseAdded: (expense: Omit<Expense, "id" | "userId">, file?: File) => Promise<void>;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExpenseForm: React.FC<ExpenseFormProps> = ({ onAddExpense, categories, payers, onAddCategory, onEditCategory, onDeleteCategory, onAddPayer, onEditPayer, onDeletePayer, onEditPayerColor, payerColors, bankAccounts, onUpdateBankAccount, onExpenseAdded }) => {
  const [expense, setExpense] = useState({
    description: '',
    date: '',
    payer: '',
    category: '',
    shared: false,
    sharedWith: '',
    amount: '',
  });
  const [newCategory, setNewCategory] = useState('');
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sortedCategories, setSortedCategories] = useState<string[]>([]);
  const [newPayer, setNewPayer] = useState('');
  const [isAddingPayer, setIsAddingPayer] = useState(false);
  const [editingPayer, setEditingPayer] = useState<string | null>(null);
  const intl = useIntl();

  const [openPayerDialog, setOpenPayerDialog] = useState(false);
  const [editingPayerName, setEditingPayerName] = useState('');
  const [newPayerName, setNewPayerName] = useState('');
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [selectedPayer, setSelectedPayer] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000');

  const [isDebt, setIsDebt] = useState(false);
  const [debtFrom, setDebtFrom] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [descriptionSuggestions, setDescriptionSuggestions] = useState<string[]>([]);

  const [selectedBank, setSelectedBank] = useState<string>('');

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const [editingCategoryEmoji, setEditingCategoryEmoji] = useState('');

  const [suggestedEmojis] = useState([
    '💰', '🍔', '🚗', '🏠', '🛒', '💊', '🎓', '✈️', '🎭', '🎁', '📚', '👕', '💻', '📱', '🏋️‍♀️', '🎨',
    '🍽️', '🍺', '🚌', '🚕', '🚉', '🏥', '💼', '🏛️', '🏪', '🏢', '🏨', '🏡', '🛋️', '🧹', '🧼', '🧺',
    '🔧', '🔨', '🛠️', '📺', '🖥️', '🖨️', '📷', '🎥', '🎸', '🎹', '🎧', '🎮', '⚽', '🏀', '🎾', '🏐',
    '🚲', '🛵', '⛽', '🅿️', '🎟️', '🎭', '🍿', '🎬', '🖼️', '🎨', '✂️', '💇‍♀️', '💆‍♀️', '💅', '👶', '🐶',
    '🐱', '🌳', '🌺', '🌻', '☂️', '🧣', '👓', '👔', '👗', '👠', '🧢', '💍', '⌚', '💼', '🎒', '👜',
    '🧳', '🔑', '🗝️', '💡', '🔌', '🔋', '📱', '☎️', '📨', '📦', '🏷️', '🔖', '💳', '💸', '🏧', '💰'
  ]);

  const longestPayerName = useMemo(() => {
    return payers.reduce((longest, current) => 
      current.length > longest.length ? current : longest
    , '');
  }, [payers]);

  useEffect(() => {
    setSortedCategories([...categories].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })));
  }, [categories]);

  const sortedPayers = useMemo(() => {
    const preferredOrder = ['Bruno', 'Simone', 'Bárbara'];
    return [
      ...preferredOrder.filter(payer => payers.includes(payer)),
      ...payers.filter(payer => !preferredOrder.includes(payer)).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    ];
  }, [payers]);

  useEffect(() => {
    // Load description suggestions from localStorage
    const savedSuggestions = localStorage.getItem('expenseDescriptionSuggestions');
    if (savedSuggestions) {
      setDescriptionSuggestions(JSON.parse(savedSuggestions));
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    if (name === 'category' && value === 'new') {
      setIsAddingCategory(true);
    } else if (name === 'payer' && value === 'new') {
      setIsAddingPayer(true);
    } else {
      setExpense(prev => ({
        ...prev,
        [name]: name === 'shared' ? (e.target as HTMLInputElement).checked : value,
      }));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Permite números, um separador decimal (vírgula ou ponto) e até duas casas decimais
    // Substitui a vírgula por ponto para garantir consistência
    const sanitizedValue = value.replace(',', '.');
    if (/^\d*\.?\d{0,2}$/.test(sanitizedValue)) {
      setExpense(prev => ({ ...prev, amount: sanitizedValue }));
    }
  };

  const formatAmount = (value: string) => {
    // Substitui vírgulas por pontos para cálculos internos
    return value.replace(',', '.');
  };

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!expense.description || !expense.date || !expense.payer || !expense.category || parseFloat(formatAmount(expense.amount)) <= 0) {
      alert(intl.formatMessage({ id: 'fillAllFields' }));
      return;
    }

    try {
      setUploading(true);
      const expenseAmount = parseFloat(formatAmount(expense.amount));
      const newExpense: Omit<Expense, 'id' | 'userId'> = {
        description: expense.description,
        date: expense.date,
        payer: isDebt ? debtFrom : expense.payer,
        category: expense.category,
        shared: expense.shared,
        sharedWith: expense.shared ? expense.sharedWith || '' : undefined,
        amount: expenseAmount,
        debtFrom: isDebt ? expense.payer : null,
        isDebt: isDebt,
        bankAccountId: selectedBank || null,  // This should now be valid
      };

      await onAddExpense(newExpense, selectedFile || undefined);

      // Update bank account balance only if a bank account is selected
      if (selectedBank) {
        const selectedBankAccount = bankAccounts.find(account => account.id === selectedBank);
        if (selectedBankAccount) {
          const newBalance = selectedBankAccount.balance - expenseAmount;
          await onUpdateBankAccount(selectedBank, newBalance);
        }
      }

      setExpense({
        description: '',
        date: '',
        payer: '',
        category: '',
        shared: false,
        sharedWith: '',
        amount: '',
      });
      setSelectedFile(null);
      setIsDebt(false);
      setDebtFrom('');
      setSelectedBank('');
      setOpenSuccessDialog(true);
      
      // Set a timeout to close the dialog and refresh the page
      setTimeout(() => {
        setOpenSuccessDialog(false);
        // Use window.location.reload() to refresh the page
        window.location.reload();
      }, 2000);

    } catch (error) {
      console.error('Error adding expense:', error);
      alert(intl.formatMessage({ id: 'errorAddingExpense' }));
    } finally {
      setUploading(false);
    }
  };

  const handleEditCategory = (category: string) => {
    setEditingCategory(category);
    setNewCategory(category);
  };

  const handleSaveEditedCategory = () => {
    const trimmedCategory = newCategory.trim();
    if (editingCategory && trimmedCategory !== '' && trimmedCategory !== editingCategory) {
      if (!categories.includes(trimmedCategory)) {
        onEditCategory(editingCategory, trimmedCategory, editingCategoryEmoji);
        setExpense(prev => ({
          ...prev,
          category: prev.category === editingCategory ? trimmedCategory : prev.category
        }));
        setEditingCategory(null);
        setNewCategory('');
        setEditingCategoryEmoji('');
      } else {
        alert(`${intl.formatMessage({ id: 'categoryExists' })} "${trimmedCategory}".`);
      }
    } else {
      setEditingCategory(null);
      setNewCategory('');
      setEditingCategoryEmoji('');
    }
  };

  const handleAddCategory = () => {
    const trimmedCategory = newCategory.trim();
    if (trimmedCategory !== '') {
      if (!categories.includes(trimmedCategory)) {
        onAddCategory(trimmedCategory);
        setIsAddingCategory(false);
        setExpense({...expense, category: trimmedCategory});
        setNewCategory('');
      } else {
        alert(`${intl.formatMessage({ id: 'categoryExists' })} "${trimmedCategory}".`);
      }
    }
  };

  const handleAddPayer = () => {
    const trimmedPayer = newPayer.trim();
    if (trimmedPayer !== '') {
      if (!payers.includes(trimmedPayer)) {
        onAddPayer(trimmedPayer);
        setIsAddingPayer(false);
        setExpense({...expense, payer: trimmedPayer});
        setNewPayer('');
      } else {
        alert(`${intl.formatMessage({ id: 'payerExists' })} "${trimmedPayer}".`);
      }
    }
  };

  const handleEditPayer = (payer: string) => {
    setEditingPayer(payer);
    setNewPayer(payer);
  };

  const handleSaveEditedPayer = () => {
    if (editingPayer && newPayer.trim() !== '' && newPayer !== editingPayer) {
      onEditPayer(editingPayer, newPayer.trim());
      setExpense(prev => ({
        ...prev,
        payer: prev.payer === editingPayer ? newPayer.trim() : prev.payer,
        sharedWith: prev.sharedWith === editingPayer ? newPayer.trim() : prev.sharedWith
      }));
    }
    setEditingPayer(null);
    setNewPayer('');
  };

  const handleDeleteCategory = (category: string) => {
    if (window.confirm(`${intl.formatMessage({ id: 'confirmDelete' })} "${category}"?`)) {
      onDeleteCategory(category);
      if (expense.category === category) {
        setExpense(prev => ({ ...prev, category: '' }));
      }
    }
  };

  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [editingCategoryName, setEditingCategoryName] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
    setEditingCategoryName('');
    setNewCategoryName('');
  };

  const handleEditCategoryStart = (category: string) => {
    setEditingCategoryName(category);
    setNewCategoryName(category);
    setEditingCategoryEmoji(categoryEmojis[category] || '');
  };

  const handleEditCategoryConfirm = async () => {
    if (newCategoryName.trim() !== '' && (newCategoryName !== editingCategoryName || editingCategoryEmoji !== categoryEmojis[editingCategoryName])) {
      try {
        await onEditCategory(editingCategoryName, newCategoryName.trim(), editingCategoryEmoji);
        
        // Atualiza o estado local
        setExpense(prev => ({
          ...prev,
          category: prev.category === editingCategoryName ? newCategoryName.trim() : prev.category
        }));
        
        // Atualiza a lista de categorias localmente
        const updatedCategories = categories.map(cat => 
          cat === editingCategoryName ? newCategoryName.trim() : cat
        );
        setSortedCategories([...updatedCategories].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })));
        
        // Reseta os estados de edição
        setEditingCategoryName('');
        setNewCategoryName('');
        setEditingCategoryEmoji('');
        
        // Fecha o diálogo de edição
        setOpenCategoryDialog(false);

        console.log('Categoria atualizada com sucesso');
      } catch (error) {
        console.error('Erro ao atualizar categoria:', error);
        alert(intl.formatMessage({ id: 'errorUpdatingCategory' }));
      }
    }
  };

  const handleDeleteCategoryConfirm = (category: string) => {
    if (window.confirm(`${intl.formatMessage({ id: 'confirmDelete' })} "${category}"?`)) {
      onDeleteCategory(category);
    }
  };

  const handleDateChange = (newValue: Date | null) => {
    if (newValue instanceof Date) {
      // Ensure the date is not affected by timezone
      const localDate = new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000);
      setExpense(prev => ({ ...prev, date: localDate.toISOString().split('T')[0] }));
    } else if (newValue === null) {
      setExpense(prev => ({ ...prev, date: '' }));
    }
  };

  const handleOpenPayerDialog = () => {
    setOpenPayerDialog(true);
  };

  const handleClosePayerDialog = () => {
    setOpenPayerDialog(false);
    setEditingPayerName('');
    setNewPayerName('');
  };

  const handleEditPayerStart = (payer: string) => {
    setEditingPayerName(payer);
    setNewPayerName(payer);
  };

  const handleEditPayerConfirm = () => {
    if (newPayerName.trim() !== '' && newPayerName !== editingPayerName) {
      onEditPayer(editingPayerName, newPayerName.trim());
      setEditingPayerName('');
      setNewPayerName('');
    }
  };

  const handleDeletePayerConfirm = (payer: string) => {
    if (window.confirm(`${intl.formatMessage({ id: 'confirmDelete' })} "${payer}"?`)) {
      onDeletePayer(payer);
    }
  };

  const handleOpenColorPicker = (payer: string) => {
    setSelectedPayer(payer);
    setSelectedColor(payerColors[payer] || '#000000');
    setOpenColorPicker(true);
  };

  const handleCloseColorPicker = () => {
    setOpenColorPicker(false);
  };

  const handleColorChange = (color: any) => {
    setSelectedColor(color.hex);
  };

  const handleColorChangeComplete = () => {
    onEditPayerColor(selectedPayer, selectedColor);
    setOpenColorPicker(false);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: string) => option,
  });

  const handleDescriptionChange = (event: React.SyntheticEvent, newValue: string | null) => {
    if (typeof newValue === 'string') {
      setExpense(prev => ({ ...prev, description: newValue }));
      
      // Update suggestions
      if (!descriptionSuggestions.includes(newValue)) {
        const newSuggestions = [...descriptionSuggestions, newValue].slice(-20); // Keep last 20 suggestions
        setDescriptionSuggestions(newSuggestions);
        localStorage.setItem('expenseDescriptionSuggestions', JSON.stringify(newSuggestions));
      }
    }
  };

  const handleSharedWithChange = (event: SelectChangeEvent<string>) => {
    setExpense(prev => ({ ...prev, sharedWith: event.target.value }));
  };

  const handleDebtFromChange = (event: SelectChangeEvent<string>) => {
    setDebtFrom(event.target.value);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      // Adicione aqui qualquer lógica de carregamento necessária
      setIsLoading(false);
    };

    loadData();
  }, []);

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {error && <Typography color="error">{error}</Typography>}
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="category-label"><FormattedMessage id="category" /></InputLabel>
              <Select
                labelId="category-label"
                label={intl.formatMessage({ id: 'category' })}
                name="category"
                value={expense.category}
                onChange={handleChange}
                required
              >
                <MenuItem value=""><FormattedMessage id="selectCategory" /></MenuItem>
                {sortedCategories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {categoryEmojis[category] || ''} {category}
                  </MenuItem>
                ))}
                <MenuItem value="new">+ <FormattedMessage id="addNewCategory" /></MenuItem>
              </Select>
            </FormControl>
            <Tooltip title={intl.formatMessage({ id: 'manageCategories' })}>
              <IconButton
                size="small"
                onClick={handleOpenCategoryDialog}
                sx={{ ml: 1 }}
              >
                <Settings fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>

          {isAddingCategory && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label={intl.formatMessage({ id: 'newCategory' })}
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                fullWidth
              />
              <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddCategory}>
                <FormattedMessage id="add" />
              </Button>
            </Box>
          )}
          
          <Autocomplete
            freeSolo
            options={descriptionSuggestions}
            value={expense.description}
            onChange={handleDescriptionChange}
            onInputChange={(event, newInputValue) => {
              setExpense(prev => ({ ...prev, description: newInputValue }));
            }}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'description' })}
                name="description"
                required
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable browser autocomplete
                }}
              />
            )}
          />
          
          <DatePicker
            label={intl.formatMessage({ id: 'date' })}
            value={expense.date ? new Date(expense.date) : null}
            onChange={(newValue) => handleDateChange(newValue as Date | null)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps?.endAdornment}
                    </React.Fragment>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    cursor: 'pointer',
                  },
                  '& .MuiInputBase-input': {
                    cursor: 'pointer',
                  },
                }}
                onClick={(event) => {
                  if (params.inputProps?.onClick) {
                    (params.inputProps.onClick as React.MouseEventHandler<HTMLDivElement>)(event);
                  }
                }}
              />
            )}
            inputFormat="dd/MM/yyyy"
          />
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="payer-label" shrink={!!expense.payer}>{intl.formatMessage({ id: 'payer' })}</InputLabel>
              <Select
                labelId="payer-label"
                label={intl.formatMessage({ id: 'payer' })}
                name="payer"
                value={expense.payer}
                onChange={handleChange}
                required
                notched
              >
                <MenuItem value=""><FormattedMessage id="selectPayer" /></MenuItem>
                {sortedPayers.map((payer) => (
                  <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                ))}
                <MenuItem value="new">+ <FormattedMessage id="addNewPayer" /></MenuItem>
              </Select>
            </FormControl>
            <Tooltip title={intl.formatMessage({ id: 'managePayers' })}>
              <IconButton
                size="small"
                onClick={handleOpenPayerDialog}
                sx={{ ml: 1 }}
              >
                <Settings fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>

          {isAddingPayer && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label={intl.formatMessage({ id: 'newPayer' })}
                value={newPayer}
                onChange={(e) => setNewPayer(e.target.value)}
                fullWidth
              />
              <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddPayer}>
                <FormattedMessage id="add" />
              </Button>
            </Box>
          )}
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              type="text"
              label={`${intl.formatMessage({ id: 'amount' })} (€)`}
              name="amount"
              value={expense.amount}
              onChange={handleAmountChange}
              required
              fullWidth
              InputProps={{ 
                style: { textAlign: 'right' },
              }}
              inputProps={{
                inputMode: 'decimal',
                pattern: '[0-9]*[.,]?[0-9]*'
              }}
              onKeyPress={(e) => {
                const pattern = /[0-9.,]/;
                if (!pattern.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            
            <FormControl fullWidth>
              <InputLabel id="bank-account-label">{intl.formatMessage({ id: 'bankAccount' })}</InputLabel>
              <Select
                labelId="bank-account-label"
                label={intl.formatMessage({ id: 'bankAccount' })}
                value={selectedBank}
                onChange={(e) => setSelectedBank(e.target.value)}
                // Remove the 'required' prop
              >
                <MenuItem value=""><FormattedMessage id="selectBank" /></MenuItem>
                {bankAccounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {`${account.bankName} - ${account.accountName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    checked={expense.shared}
                    onChange={handleChange}
                    name="shared"
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Share sx={{ mr: 1 }} />
                    <FormattedMessage id="shared" />
                  </Box>
                }
              />
              {expense.shared && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="shared-with-label"><FormattedMessage id="sharedWith" /></InputLabel>
                  <Select
                    labelId="shared-with-label"
                    label={intl.formatMessage({ id: 'sharedWith' })}
                    name="sharedWith"
                    value={expense.sharedWith}
                    onChange={handleSharedWithChange}
                    required
                  >
                    <MenuItem value=""><FormattedMessage id="selectPerson" /></MenuItem>
                    {payers.filter(p => p !== expense.payer).map(payer => (
                      <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </FormControl>

            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    checked={isDebt}
                    onChange={(e) => setIsDebt(e.target.checked)}
                    name="isDebt"
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CreditCard sx={{ mr: 1 }} />
                    <FormattedMessage id="isDebt" />
                  </Box>
                }
              />
              {isDebt && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="debt-from-label"><FormattedMessage id="debtFrom" /></InputLabel>
                  <Select
                    labelId="debt-from-label"
                    label={intl.formatMessage({ id: 'debtFrom' })}
                    value={debtFrom}
                    onChange={handleDebtFromChange}
                    required={isDebt}
                  >
                    <MenuItem value=""><FormattedMessage id="selectPerson" /></MenuItem>
                    {payers.filter(p => p !== expense.payer).map(payer => (
                      <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </FormControl>
          </Box>

          <Box>
            <Input
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="receipt-file-input"
            />
            <label htmlFor="receipt-file-input">
              <Button
                variant="outlined"
                component="span"
                startIcon={<Attachment />}
              >
                <FormattedMessage id="attachReceipt" />
              </Button>
            </label>
            {selectedFile && <Typography variant="caption" sx={{ ml: 2 }}>{selectedFile.name}</Typography>}
          </Box>

          <Button type="submit" variant="contained" color="primary" startIcon={<Save />} disabled={uploading}>
            {uploading ? intl.formatMessage({ id: 'uploading' }) : intl.formatMessage({ id: 'addExpense' })}
          </Button>
        </Box>

        <Dialog 
          open={openCategoryDialog} 
          onClose={handleCloseCategoryDialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            <Typography variant="h6">
              <FormattedMessage id="manageCategories" />
            </Typography>
          </DialogTitle>
          <DialogContent>
            <List>
              {sortedCategories.map((category) => (
                <ListItem 
                  key={category}
                  disablePadding
                  secondaryAction={
                    editingCategoryName !== category && (
                      <>
                        <IconButton onClick={() => handleEditCategoryStart(category)}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteCategoryConfirm(category)}>
                          <Delete />
                        </IconButton>
                      </>
                    )
                  }
                >
                  {editingCategoryName === category ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', py: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <TextField
                          value={editingCategoryEmoji}
                          onChange={(e) => setEditingCategoryEmoji(e.target.value)}
                          placeholder="Emoji"
                          sx={{ width: '60px', mr: 2 }}
                          inputProps={{ style: { textAlign: 'center' } }}
                        />
                        <TextField
                          value={newCategoryName}
                          onChange={(e) => setNewCategoryName(e.target.value)}
                          fullWidth
                          label={intl.formatMessage({ id: 'categoryName' })}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                        {suggestedEmojis.map((emoji) => (
                          <Button
                            key={emoji}
                            onClick={() => setEditingCategoryEmoji(emoji)}
                            sx={{ minWidth: 'unset', p: 0.5 }}
                          >
                            {emoji}
                          </Button>
                        ))}
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button 
                          onClick={() => {
                            setEditingCategoryName('');
                            setNewCategoryName('');
                            setEditingCategoryEmoji('');
                          }}
                          color="inherit"
                          sx={{ mr: 1 }}
                        >
                          <FormattedMessage id="cancel" />
                        </Button>
                        <Button 
                          onClick={handleEditCategoryConfirm}
                          variant="contained"
                          color="primary"
                        >
                          <FormattedMessage id="save" />
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <ListItemButton>
                      <ListItemText 
                        primary={
                          <Typography>
                            <span style={{ marginRight: '8px' }}>{categoryEmojis[category] || ''}</span>
                            {category}
                          </Typography>
                        } 
                      />
                    </ListItemButton>
                  )}
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCategoryDialog}>
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openPayerDialog} onClose={handleClosePayerDialog}>
          <DialogTitle><FormattedMessage id="managePayers" /></DialogTitle>
          <DialogContent>
            <List>
              {sortedPayers.map((payer) => (
                <ListItem key={payer}>
                  {editingPayerName === payer ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                      <TextField
                        value={newPayerName}
                        onChange={(e) => setNewPayerName(e.target.value)}
                        fullWidth
                      />
                      <IconButton onClick={handleEditPayerConfirm}>
                        <Save />
                      </IconButton>
                      <IconButton onClick={() => setEditingPayerName('')}>
                        <Cancel />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <ListItemText primary={payer} />
                      <IconButton onClick={() => handleEditPayerStart(payer)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeletePayerConfirm(payer)}>
                        <Delete />
                      </IconButton>
                      <IconButton onClick={() => handleOpenColorPicker(payer)}>
                        <div style={{ width: 20, height: 20, backgroundColor: payerColors[payer] || '#000000', borderRadius: '50%' }} />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePayerDialog}><FormattedMessage id="close" /></Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openColorPicker} onClose={handleCloseColorPicker}>
          <DialogTitle><FormattedMessage id="selectColor" /></DialogTitle>
          <DialogContent>
            <ChromePicker color={selectedColor} onChange={handleColorChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseColorPicker}><FormattedMessage id="cancel" /></Button>
            <Button onClick={handleColorChangeComplete}><FormattedMessage id="save" /></Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            padding: '24px'
          }}>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h6" align="center">
              <FormattedMessage id="expenseSubmittedSuccessfully" />
            </Typography>
          </DialogContent>
        </Dialog>
      </motion.div>
    </LocalizationProvider>
  );
};

export default ExpenseForm;